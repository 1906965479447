import React from "react"
import { Link } from "gatsby"
import BlogStyles from "./header.module.scss"
import headerStyles from "./header.module.scss"

const HeaderLimpeza = () => {
  return(
    <nav>
    <ul className={BlogStyles.navList}>
      <li>
        <Link
            className={headerStyles.navItem}
            activeClassName={headerStyles.activeNavItem}
          to="/alcool"
        >
          Alcohol
        </Link>
      </li> 
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/limpezaderoupa"
        >
          Laundry Soap
        </Link>
      </li>
    </ul>
  </nav>
  )
}
export default HeaderLimpeza